@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-in {
	animation: fadeIn 800ms;
}

img{
	cursor: pointer;
}

