@import "Themes/constants.scss";

.root {
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}

	.container {
		position: relative;
		width: 656px;
		max-height: 90%;
		background: var(--color-primary-6);
		box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
		overflow: auto;

		@media (max-width: $screen-s) {
			width: 90%;
		}

		.cross {
			position: absolute;
			top: 32px;
			right: 32px;
			cursor: pointer;
		}
		.sub-container {
			padding: 56px 90px;
			font-family: var(--font-primary);
			font-style: normal;

			@media (max-width: $screen-s) {
				padding: 90px 24px;
			}

			.content {
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: var(--color-primary-1);
				max-width: 530px;
			}

			.no-max-width {
				max-width: none;
			}
		}
	}

	.transparant-background {
		background-color: transparent;
		box-shadow: none;
	}

	&[data-side-background="true"] {
		.container {
			max-width: 711px;
			// height: 391px;

			.cross {
				@media (max-width: $screen-s) {
					right: 32px;
					top: 100px;
				}
				@media (max-width: $screen-xs) {
					right: 32px;
					top: 100px;
				}
			}

			.sub-container {
				padding: 0;
				display: flex;

				.content {
					max-width: 711px;
				}

				@media (max-width: $screen-s) {
					display: block;
				}

				.banner {
					@media (max-width: $screen-s) {
						overflow: hidden;
					}
				}
			}
		}

		.side-image {
			height: 100%;
			@media (max-width: $screen-s) {
				display: none;
			}
		}

		.top-image {
			@media (min-width: $screen-s) {
				display: none;
			}
			@media (max-width: $screen-s) {
				width: 100%;
				max-height: 82px;
				min-height: 82px;
			}
		}
	}
}
