@import "Themes/constants.scss";

.root {
	position: relative;
	display: grid;
	grid-template-columns: 17% 1fr 10% fit-content(0px);
	grid-gap: 60px;
	align-items: center;
	justify-content: space-between;
	background-color: var(--color-primary-2);
	min-height: 210px;
	padding: 42px;
	border-top: 1px solid var(--color-primary-1);
	border-bottom: 1px solid var(--color-primary-1);
	width: 100%;

	@media (max-width: 1260px) {
		grid-template-columns: 1fr;
		grid-gap: 16px;
		padding: 24px;
	}

	@media (max-width: $screen-s) {
		padding: 16px;
	}

	&[data-clickable="true"] {
		&:hover {
			background: var(--color-primary-1);
			.left .container .background-linear {
				background: transparent;
			}
		}

		.navigator {
			&:hover {
				cursor: pointer;
			}
		}

		.container {
			cursor: pointer;
			&:hover {
				background: var(--color-primary-1);
			}
			z-index: 0;
		}
	}

	.navigator {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
	}

	.collection-name {
		max-width: 400px;
		margin-bottom: 16px;

		h1 {
			margin: 0;
			font-weight: 800;
			font-size: 22px;
			line-height: 28px;
			color: var(--color-primary-7);
			pointer-events: none;
			cursor: pointer;
			overflow-wrap: break-word;
		}

		@media (max-width: $screen-s) {
			max-width: calc(100vw - 170px);
		}
	}

	.left {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.container {
			display: flex;
			align-items: center;
			position: relative;
			min-height: 190px;
			width: 100%;
			margin-bottom: -40px;
			margin-top: -40px;

			@media (max-width: 1260px) {
				margin: 0;
			}

			.background-linear {
				position: absolute;

				height: 53px;
				width: 100%;
				bottom: -9px;
				z-index: 2;

				@media (max-width: 1260px) {
					display: none;
				}
			}

			.scrollable-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				max-height: 190px;
				overflow-y: auto;
				z-index: 1;
				padding-right: 5px;

				@media (max-width: 1260px) {
					max-height: 100%;
					overflow-y: none;
				}

				.collection-owner {
					margin: 0;
					font-weight: 400;
					font-size: 15px;
					line-height: 22px;
					color: var(--color-primary-5);
					z-index: 1;
					width: fit-content;
					.collection-owner-pseudo {
						margin-top: 6px;
						font-weight: 400 !important;
						color: var(--color-primary-7) !important;
					}
				}

				h1 {
					margin-top: 18px;
					z-index: 1;
				}

				p {
					margin-top: 6px;
					z-index: 1;
				}

				.tags {
					display: flex;
					flex-wrap: wrap;
					> * {
						z-index: 1;
						margin: 17px 5px 0 0;
					}
				}
			}
		}

		.image-container-responsive {
			z-index: 1;
			display: none;
			align-items: center;
			justify-content: flex-end;
			margin-left: 50px;

			@media (max-width: 1260px) {
				display: flex;
			}

			@media (max-width: $screen-s) {
				margin: 0;
				flex-direction: column-reverse;
				justify-content: flex-end;
			}

			.collection-image {
				z-index: 1;
				margin-left: 50px;
				width: 126px;
				height: 126px;
				object-fit: cover;
				@media (max-width: $screen-s) {
					margin-left: 0;
					margin-bottom: 16px;
					width: 100px;
					height: 100px;
				}
			}

			.like-button {
				padding: 18px;
				position: relative;
				z-index: 1;
			}
		}
	}

	.middle {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;

		> :not(:last-child) {
			margin-bottom: 14px;
		}

		.number-collectibles {
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;
			justify-content: space-between;

			margin-bottom: 6px;

			p {
				margin: 0;
				z-index: 1;
				font-weight: 500;
				font-size: 11px;
				line-height: 20px;
				color: var(--color-primary-3);
				white-space: nowrap;

				strong {
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					color: inherit;
				}
			}

			.total {
				z-index: 1;
				font-weight: 400;
				font-size: 15px;
				line-height: 20px;
				color: var(--color-primary-7);

				strong {
					font-weight: 700;
					font-size: 28px;
					line-height: 34px;

					@media screen and (max-width: 545px) {
						font-size: 20px;
					}
				}
			}

			.total-split {
				z-index: 1;
				display: flex;
				align-items: baseline;

				> :not(:last-child) {
					margin-right: 4px;
				}

				.minted {
					color: var(--color-progress-bar-minted);
				}

				.available {
					color: var(--color-progress-bar-available);
				}

				.reserved {
					color: var(--color-progress-bar-reserved);
				}
			}
		}
	}

	.like-edit-container {
		display: flex;
		align-items: center;
		padding-right: 32px;

		.edit-icon {
			z-index: 1;
			padding: 14px;
			border-radius: 50%;
			background-color: var(--color-primary-7);

			svg {
				path {
					fill: var(--color-primary-3);
				}
			}
		}

		.like-button {
			padding: 18px;
			position: relative;
			z-index: 1;
		}

		@media screen and (max-width: 1260px) {
			display: none;
		}
	}

	.right {
		margin: -42px;
		display: grid;
		width: 210px;
		height: -webkit-fill-available;
		max-height: 210px;

		.collection-image {
			z-index: 1;
			width: 100%;
			height: 100%;
			max-height: 210px;
			object-fit: cover;
		}

		@media screen and (max-width: 1260px) {
			display: none;
		}
	}
}

[theme-mode="dark"] {
	.background-linear {
		background: linear-gradient(0deg, var(--color-primary-2) 12.22%, rgba(20, 20, 20, 0) 100%);
	}
}
