@import "Themes/constants.scss";

.root {
	position: relative;
	background-color: var(--color-background);
	box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
	height: 6px;
	border-radius: 5px;
	overflow: hidden;

	@media (max-width: $screen-s) {
		display: none;
	}
}

.progress {
	position: absolute;
	border-radius: 5px;
	display: block;
	width: 100%;
	height: 100%;
	transition: width 300ms;
	background-color: (var(--color-primary-5));
}
