@import "Themes/constants.scss";

.root {
	align-self: flex-start;
	width: 69px;
	height: 100%;
	padding: 42px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: var(--color-primary-1);
	user-select: none;
	overflow: auto;

	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 24px;

		.logo {
			cursor: pointer;
		}
	}

	nav {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		> :not(:last-child) {
			margin-bottom: 24px;
		}
	}

	.footer-icons-action {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 32px;
		display: inline-flex;
		justify-content: space-evenly;
		align-items: center;
		>*:not(:last-child){
			margin-bottom: 20px;
		}

		.menu-arrow-container {
			pointer-events: all;
			transform: rotate(180deg);
			cursor: pointer;

			svg:hover {
				path {
					fill: var(--color-primary-8);
				}

				circle {
					stroke: var(--color-primary-8);
				}
			}
		}

		.aboutUs {
			margin-top: 15px;
			margin-bottom: -30px;
		}
	}
}

.hide-scrollbar {
	&::-webkit-scrollbar-thumb {
		visibility: hidden;
	}
}


