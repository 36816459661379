.root {
	user-select: none;
	cursor: pointer;
	position: relative;
	&:active {
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.2;
			background-color: var(--color-btn-txt-ghost-disabled);
			border-radius: 3px;
		}
	}
}
