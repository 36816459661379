.root {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: var(--color-primary-4);
	.copy-icon {
		vertical-align: middle;
		margin-left: 8px;
	}
}
