.root {
	position: relative;
	background: var(--color-generic-white);
	width: 105px;
	height: 55px;
	border-radius: 100px;
	transition: all 250ms ease-in-out;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */

	&[data-disabled="true"] {
		cursor: not-allowed;
	}

	.point {
		margin: 0 7px;
		width: 40px;
		height: 40px;
		border-radius: 30px;
		background: var(--color-primary-2);
		transition: all 200ms ease-in-out;
		z-index: 2;
	}
	.on {
		position: absolute;
		left: 20px;
	}
	.off {
		position: absolute;
		right: 20px;
		color: var(--color-primary-2);
	}

	&[data-active="true"] {
		background: var(--color-neutral-2);

		.point {
			transform: translateX(120%);
			background: var(--color-generic-white);
		}
	}
}
