@import "Themes/constants.scss";

.root {
	border-radius: 46px;
	padding: 4px 12px;
	width: fit-content;

	font-weight: 600;
	font-size: 13px;
	line-height: 20px;

	color: var(--color-neutral-11);
	background: var(--color-neutral-5);

	&[data-type="ONCHAIN"] {
		color: var(--color-generic-white);
		background: var(--color-neutral-4);
	}
}

