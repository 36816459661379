@import "Themes/constants.scss";

.root {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.image-container {
		height: 24px;
	}

	.like-count {
		margin: 0px;
		font-weight: 500;
		font-size: 10px;
		line-height: 14px;
		color: var(--color-primary-7);
	}
}
