@import "Themes/constants.scss";

.root {
	position: fixed;
	height: 100%;
	z-index: 15;

	@media (max-width: $screen-m) {
		display: none;
	}
}
