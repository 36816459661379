@import "Themes/constants.scss";

.root {
	display: flex;
	flex-direction: column;

	p {
		align-self: flex-end;
		margin: 0;
		margin-top: 10px;
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		color: var(--color-neutral-2);
		text-transform: uppercase;
	}
}
