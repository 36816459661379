@import "Themes/constants.scss";

.root {
	position: relative;
	margin-bottom: 32px;
	.profile-icon-container {
		border-radius: 100px;
		border: 1px solid var(--color-primary-5);
		padding: 8px;
		cursor: pointer;

		> svg {
			width: 20px;
			height: 20px;
		}
		> svg > path {
			fill: var(--color-primary-8);
		}
	}

	.user-container-responsiv {
		position: absolute;
		background: var(--color-primary-1);
		padding: 18px 40px;
		left: 53px;
		top: -30px;
		width: 214px;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}

	.user-container-responsiv,
	.user-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (max-width: $screen-m) {
			align-items: flex-start;
		}

		> :not(:last-child) {
			margin-bottom: 12px;
		}

		.pseudo-container {
			max-width: 180px;
			.pseudo {
				margin: 0;
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 800;
				font-size: 22px;
				line-height: 28px;
				color: var(--color-primary-7);
				overflow-wrap: break-word;
			}

			svg {
				padding-top: 5px;
				cursor: pointer;
				path {
					fill: var(--color-primary-8);
				}
			}
		}
	}
}
