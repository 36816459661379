@import "Themes/constants.scss";

.root {
	position: fixed;
	height: 65px;
	width: 100%;
	z-index: 11;
	transition: height 0.3s $custom-easing, transform 0.3s ease-out;
	display: inline-flex;
	flex-direction: column;
	background-color: var(--color-primary-1);
	user-select: none;

	@media screen and (min-width: ($screen-m + 1)) {
		display: none;
	}
	.top-menu {
		position: relative;
		height: 100%;
		.burger {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;
			height: 100%;

			.logo {
				img {
					width: 80px;
					float: left;
				}
			}

			svg {
				float: right;
			}
			.burger-menu {
				position: absolute;
				right: 16px;
				.burger-icon {
					width: 24px;
					height: 24px;
					cursor: pointer;

					rect {
						fill: var(--color-primary-8);
					}
				}
			}
		}

		.side-menu {
			height: 100vh;
			width: 290px;
			transform: translateX(290px);
			position: absolute;
			top: 0;
			right: 0;
			background-color: var(--color-primary-1);
			overflow: auto;
			height: 100vh;
			transition: transform 0.4s ease-in-out;
			-webkit-transition: transform 0.4s ease-in-out;
			&[data-top-menu-status="opened"] {
				transform: translateX(0px);
			}
			.side-menu-content {
				display: flex;
				flex-direction: column;
				padding-top: 16px;
				padding-left: 56px;
				padding-right: 56px;
				padding-bottom: 100px;
				height: 100%;
				justify-content: space-between;
				.top-container {
					.top-menu-header {
						display: inline-flex;
						justify-content: space-between;
						padding-right: 16px;
						.close-icon {
							position: absolute;
							top: 22px;
							right: 21px;
							width: 24px;
							height: 24px;
							cursor: pointer;

							path {
								stroke: var(--color-primary-8);
							}
						}
						> img {
							padding-top: 26px;
						}
						.logo {
							padding-top: 26px;
						}
					}

					header {
						margin-top: 44px;
						margin-bottom: 44px;
					}
				}

				nav {
					> :not(:last-child) {
						margin-bottom: 24px;
					}
				}

				footer {
					display: flex;
					flex-direction: column;
					width: 100%;
					.footer-button-container {
						display: flex;
						margin-top: 32px;
						
						button {
							width: 100%;
						}
					}
					.footer-icons-action {
						margin: 48px 0 16px 0;
						display: inline-flex;
						justify-content: space-evenly;
					}
				}
			}
		}
	}
}

.background {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 11;
}
