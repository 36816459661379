@import "Themes/constants.scss";
@import "Themes/fonts.scss";
@import "Themes/modes.scss";
@import "Themes/variables.scss";

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-primary-2);
	font-family: var(--font-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 0;
	overflow-x: hidden;
	max-width: 100vw;

	#root {
		display: flex;
		flex-direction: column;
		max-height: 100vh;
		overflow: hidden;

		& > *:not(header):not(footer) {
			flex-grow: 1;
		}
	}
}

* {
	box-sizing: border-box;

	&::-webkit-scrollbar {
		width: 16px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: none;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background-color: rgba(101, 100, 100, 0.6);
		background-clip: padding-box;
		border-radius: 9999px;
		border: 5px solid transparent;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: rgba(101, 100, 100, 0.8);
		background-clip: padding-box;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: white !important;
	-webkit-background-clip: text;
	background-clip: text;
}

label.required::after {
	content: "*";
}

select {
	width: 100%;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: "";

	color: var(--color-primary-8);
	background-color: var(--color-primary-1);
	&:focus-visible {
		outline: none;
		border: none;
	}

	option {
		padding: 10px 0;
	}
}

input:placeholder-shown {
	text-overflow: ellipsis;
}

a {
	text-decoration: none;
}

button {
	padding: 0;
	margin: 0;
}

input[type="range"] {
	overflow: hidden;
	width: 80px;
	-webkit-appearance: none;
	background-color: lightgray;
	border-radius: 50px;
	cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
	height: 10px;
	-webkit-appearance: none;
	color: #13bba4;
	margin-top: -1px;
}

input[type="range"]::-webkit-slider-thumb {
	width: 0;
	-webkit-appearance: none;
	height: 10px;
	box-shadow: -80px 0 0 80px var(--color-neutral-2);
}
