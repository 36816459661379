.root {
	cursor: pointer;
	svg:hover {
		circle {
			stroke: var(--color-primary-8);
		}
		path {
			stroke: var(--color-primary-8);
		}
	}
}
