@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	height: 92vh;
	.container {
		text-align: center;
		margin: auto;
		max-width: 600px;
        @media (max-width: $screen-s) {max-width: 300px;}

		.text {
			background: var(--color-gradient-1);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			.title {
				font-weight: 600;
				font-size: 300px;
				line-height: 363px;
				@media (max-width: $screen-s) {
					font-weight: 600;
					font-size: 150px;
					line-height: 182px;
				}
			}
			.subtitle {
				font-weight: 600;
				font-size: 40px;
				line-height: 48px;
				margin-top: -32px;
				margin-bottom: 32px;
				@media (max-width: $screen-s) {
					font-weight: 600;
					font-size: 22px;
					line-height: 27px;
				}
			}
		}
	}
}
