.root {
	cursor: pointer;

	svg {
		path {
			fill: var(--color-primary-8);
		}
	}
}

.signIn {
	width: 50%;
}
