@import "Themes/constants.scss";

.root {
	position: relative;
	margin-left: 215px;
	transition: margin-left 300ms $custom-easing;
	box-sizing: border-box;
	overflow-y: auto;
	&.reduced{
		margin-left: 69px;

	}
	@media (max-width: $screen-m) {
		transition: none;
		margin-left: 0!important;
		margin-top: 65px;
	}

	.for-seo {
		font-size: 0;
	}
}

.content {
	padding: var(--content-padding);
	@media screen and (max-width: $screen-s) {
		padding-left: 16px;
		padding-right: 16px;
	}

	&[data-padding="false"] {
		padding: 0;
	}
}
