@import "Themes/constants.scss";

.root {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	padding: 3px 8px;
	width: fit-content;

	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	white-space: nowrap;

	color: var(--color-primary-7);
	background: var(--color-primary-3);

	&[data-variant="light"]{
		color: var(--color-primary-2);
		background: var(--color-primary-7);
	}
}

