@import "Themes/constants.scss";

.root {
	position: absolute;
	top: 0;
	left: 0;
	width: 215px;
	height: 100%;
	padding: 42px 0;
	z-index: 15;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: var(--color-primary-1);
	user-select: none;
	overflow: auto;
	transition: transform 0.3s ease-in-out;
	-webkit-transition: transform 0.3s ease-in-out;

	&.reduced {
		transform: translateX(-215px);
	}

	@media screen and (max-width: $screen-m) {
		display: none;
	}

	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 24px;
		.logo {
			cursor: pointer;
		}
	}

	nav {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		> :not(:last-child) {
			margin-bottom: 24px;
		}
	}

	footer {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 44px;

		.footer-icons-action {
			margin-top: 48px;
			margin-bottom: 16px;
			display: inline-flex;
			justify-content: space-evenly;
			align-items: center;
			width: 100%;

			.menu-arrow-container {
				pointer-events: all;
				cursor: pointer;
				transform: rotate(0deg);

				svg:hover {
					path {
						fill: var(--color-primary-8);
					}

					circle {
						stroke: var(--color-primary-8);
					}
				}
			}
		}

		.footer-button-container {
			display: flex;
			justify-content: center;

			button {
				width: 168px;
				height: 54px;
			}
		}
	}
}

.hide-scrollbar {
	&::-webkit-scrollbar-thumb {
		visibility: hidden;
	}
}
