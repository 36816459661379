@import "Themes/constants.scss";

.root {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	color: var(--color-primary-1);
	letter-spacing: -0.02em;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

