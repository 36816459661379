@import "Themes/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	margin: 0;
	padding: 40px;
	margin-top: 24px;

	.text {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--color-neutral-8);
		margin-top: 24px;
	}
}
