.route {
	display: flex;
	flex-direction: row;
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	white-space: nowrap;
	color: var(--color-primary-5);
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	text-decoration: none;
	align-items: center;
	
	&.disabled{
		pointer-events: none;
	}

	.text-container {
		text-align: center;

		&[data-reduced="false"] {
			margin-left: 12px;
			text-align: left;
		}

		.subtitle {
			font-weight: 400;
			font-size: 12px;
			white-space: break-spaces;
			overflow-wrap: break-word;
		}

		.title-container {
			display: flex;
			align-items: center;

			svg {
				path {
					fill: var(--color-primary-5);
				}
			}

			p {
				margin: 0;
			}
		}
	}

	&[data-coming-soon="true"],
	&[data-onboarding] {
		pointer-events: none;
	}

	&:hover {
		color: var(--color-primary-8);
		svg path {
			fill: var(--color-primary-8);
		}
	}

	svg {
		path {
			fill: var(--color-primary-5);
		}
	}
}

.active-route {
	color: var(--color-primary-8);

	svg path {
		fill: var(--color-primary-8);
	}
}
