@import "Themes/constants.scss";
.root {
	.popup-container {
		display: flex;

		@media (max-width: $screen-s) {
			display: block;
		}
	}

	.text {
		margin-block-start: 0;
		margin-block-end: 0;
		width: 100%;
		padding-bottom: 35px;
	}

	.content {
		padding: 75px 80px 76px 90px;

		@media (max-width: $screen-s) {
			padding : 0 40px 90px 40px;
			justify-content : center;
		}
	}

	h1 {
		margin: 0;
		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 86px;
		background: linear-gradient(252.46deg, var(--color-neutral-6) 12.24%, var(--color-neutral-2) 96%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		max-width: 451px;
		white-space: nowrap;

		@media (max-width: $screen-xs) {
			font-size: 20px;
			padding-top: 20px;
		}
		@media (max-width: $screen-s) {
			font-size: 24px;
			padding-top: 30px;
		}
}
}
