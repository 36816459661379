@import "Themes/constants.scss";
.root {
	cursor: pointer;
	display: flex;
	align-items: center;
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	color: var(--color-primary-7);
	user-select: none;
	svg:hover{
		path{
			stroke: var(--color-primary-8);
		}
		circle{
			stroke: var(--color-primary-8);
		}
	}
}
