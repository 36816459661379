.website-links {
	display: flex;
	justify-content: center;
	.link {
		text-align: center;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;

		p {
			margin: 0;
			text-decoration: underline;
			color: var(--color-primary-4);

			&:hover {
				color: var(--color-primary-8);
				cursor: pointer;
			}
		}
	}

	a {
		margin-right: 16px;
	}
}
