@import "Themes/constants.scss";

.root {
	font-family: var(--font-primary);
	font-style: normal;
	font-size: 14px;
	color: white;

	.table-container {
		margin-top: 56px;
		overflow-x: auto;

		.table {
			width: 100%;
			min-width: 1000px;
			border-collapse: collapse;
			margin: 20px 0;
			color: var(--color-primary-5);
			font-weight: 600;
			font-size: 16px;
			text-align: left;
			thead th {
				padding: 5px 9px 6px 9px;
				line-height: 1;
				border-bottom: 1px solid var(--color-primary-5);
			}
		}

		td {
			vertical-align: top;
			text-align: left;
			padding: 3px 9px;
		}
		.table-title,
		.table-cell {
			vertical-align: middle;
			text-align: left;
			padding: 10px 0;
			&.date {
				max-width: 70px;
			}
			&.email {
				div {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 80%;
				}
				max-width: 155px;
			}
			&.pseudo {
				max-width: 80px;
			}
			&.button {
				width: 300px;
			}
		}
		.row {
			cursor: pointer;
			&:hover {
				color: var(--color-primary-8);
			}
		}
		@media screen and (max-width: 1299px) {
			display: none;
		}
	}

	.cards {
		margin-top: 56px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		gap: 16px;
		@media screen and (min-width: 1300px) {
			display: none;
		}
	}

	.button-container {
		button:first-child {
			margin-right: 20px;
		}
		&.centered {
			display: flex;
			justify-content: space-between;
			button{
				width: 100%;
			}
		}
		@media screen and (max-width: $screen-s) {
			button {
				font-size: 12px;
			}
		}
	}
	.no-request {
		font-weight: 400;
		font-size: 17px;
		line-height: 34px;
		color: var(--color-primary-5);
		margin-top: 100px;
		margin-left: 32px;
	}
}
