.root {
	position: fixed;
	z-index: 100;
	width: 100%;
	height: 100%;
	background-color: black;

	.cross-container {
		position: relative;

		svg {
			position: absolute;
			top: 20px;
			right: 20px;

			path {
				stroke: white;
			}
		}
	}

	.element-container {
		display: flex;
		flex: 1;
		height: 100%;
		justify-content: center;
		align-items: center;
	}
}
