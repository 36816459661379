@import "Themes/constants.scss";

.root {
	position: relative;

	font-family: var(--font-primary);
	color: var(--color-primary-7);
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;

	.content {
		z-index: 1;
		overflow: hidden;
		p {
			margin: 0;
			word-break: break-word;
		}

		@media screen and (max-width: 1260px) {
			margin-bottom: 15px;
		}
	}

	strong {
		position: absolute;
		bottom: -25px;
		right: 0;
		text-decoration: underline;
		cursor: pointer;

		@media screen and (max-width: 1260px) {
			bottom: -10px;
		}
	}
}
