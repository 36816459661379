@import "Themes/constants.scss";

.root {
	display: grid;
	grid-template-columns: 1fr 1fr;

	label {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--color-primary-5);
		margin-right: 16px;
		white-space: nowrap;
	}

	p {
		margin: 0;
		font-weight: 400;
		font-size: 15px;
		line-height: 22px;
		color: var(--color-primary-5);
		white-space: nowrap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
