@import "Themes/constants.scss";

.root {
	display: inline-flex;
	justify-content: center;
	border: 1px solid;
	gap: 12px;
	box-sizing: border-box;
	height: fit-content;
	align-items: center;
	gap: 6px;
	background: transparent;
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	white-space: nowrap;
	user-select: none;
	cursor: pointer;

	svg {
		width: 20px;
		height: 20px;
	}

	&[sizing="s"] {
		font-size: 15px;
		line-height: 20px;
		padding: 8px 16px;
	}

	&[sizing="m"] {
		font-size: 16px;
		line-height: 28px;
		padding: 20px 24px;
	}

	&[sizing="l"] {
		font-size: 18px;
		line-height: 32px;
		padding: 24px 28px;
	}

	&[sizing="xl"] {
		justify-content: center;
		font-size: 18px;
		line-height: 28px;
		padding: 29px 75px 29px 75px;
		gap: 16px;
	}

	&[sizing="cube"] {
		font-size: 14px;
		line-height: 20px;
		padding: 8px 8px;
	}

	&[variant="primary"] {
		color: var(--color-generic-white);
		background-color: var(--color-neutral-2);
		border-color: var(--color-neutral-2);
		text-transform: uppercase;
		font-weight: 600;

		svg {
			path {
				stroke: var(--color-generic-white);
			}
		}

		&:hover {
			border-color: var(--color-neutral-2-hover);
			background-color: var(--color-neutral-2-hover);
		}

		&:active {
			border-color: var(--color-neutral-2);
			background-color: var(--color-neutral-2);
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}
	}

	&[variant="danger"] {
		color: var(--color-generic-white);
		background-color: var(--color-neutral-4);
		border-color: var(--color-neutral-4);
		text-transform: uppercase;
		font-weight: 600;

		svg {
			path {
				stroke: var(--color-generic-white);
			}
		}

		&:hover {
			border-color: var(--color-neutral-4-hover);
			background-color: var(--color-neutral-4-hover);
		}

		&:active {
			border-color: var(--color-neutral-4);
			background-color: var(--color-neutral-4);
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}
	}

	&[variant="rounded"] {
		color: var(--color-generic-white);
		background-color: var(--color-neutral-2);
		border-color: var(--color-neutral-2);
		text-transform: uppercase;
		font-weight: 400;
		font-size: 15px;
		border-radius: 50px;
		line-height: 22px;

		svg {
			path {
				stroke: var(--color-generic-white);
			}
		}

		&:hover {
			border-color: var(--color-neutral-2-hover);
			background-color: var(--color-neutral-2-hover);
		}

		&:active {
			border-color: var(--color-neutral-2);
			background-color: var(--color-neutral-2);
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}
	}

	&[variant="whiteRounded"] {
		color: var(--color-primary-1);
		background-color: var(--color-primary-8);
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		border-radius: 50px;
		border: 0;
		padding: 15px 20px;
		&:hover {
			opacity: 0.9;
		}

		&:active {
			opacity: 0.9;
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}

		svg {
			path {
				fill: var(--color-primary-1);
			}
		}
	}
	&[variant="secondary"] {
		color: var(--color-generic-white);
		background-color: var(--color-neutral-2);
		border-color: var(--color-neutral-2);
		font-weight: 600;

		svg {
			path {
				stroke: var(--color-generic-white);
			}
		}

		&:hover {
			border-color: var(--color-neutral-2-hover);
			background-color: var(--color-neutral-2-hover);
		}

		&:active {
			border-color: var(--color-neutral-2);
			background-color: var(--color-neutral-2);
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}
	}

	&[variant="ghost"] {
		color: var(--color-neutral-2);
		background-color: transparent;
		border-color: var(--color-neutral-2);
		text-transform: uppercase;
		font-weight: 600;

		svg {
			path {
				stroke: var(--color-generic-white);
			}
		}

		&:hover {
			border-color: var(--color-neutral-2-hover);
			background-color: var(--color-neutral-2-hover);
			color: var(--color-generic-white);
		}

		&:active {
			border-color: var(--color-neutral-2);
			background-color: var(--color-neutral-2);
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}
	}

	&[variant="ghostSecond"] {
		color: var(--color-primary-7);
		background-color: transparent;
		border-color: var(--color-primary-5);
		border-radius: 50px;
		font-weight: 400;
		font-size: 15px;
		line-height: 22px;
		padding: 17px 15px;

		svg {
			path {
				stroke: var(--color-generic-white);
			}
		}

		&:hover {
			border-color: var(--color-primary-8);
			color: var(--color-primary-8);
		}

		&:active {
			border-color: var(--color-primary-8);
			color: var(--color-primary-8);
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}
	}

	&[variant="transparent"] {
		width: 100%;
		margin: 0;
		border: 0;
		display: flex;
		flex-direction: column;
		border-radius: 50px;
		color: var(--color-generic-white);
		background: transparent;

		svg {
			path {
				stroke: var(--color-primary-7);
			}
		}

		&:active {
			color: var(--color-primary-7);
			border-color: var(--color-primary-5);
		}

		&:disabled {
			opacity: 0.4;
			pointer-events: none;
		}
	}

	&[variant="tertiary"] {
		color: var(--color-neutral-6);
		border-color: var(--color-neutral-6);
		background: transparent;

		svg {
			path {
				stroke: var(--color-neutral-6);
			}
		}

		&:hover {
			color: var(--color-neutral-6);
			border-color: var(--color-neutral-6);
			background-color: var(--color-neutral-2);
			background: var(--color-neutral-2);
		}

		&:active {
			color: var(--color-neutral-7);
			border-color: var(--color-neutral-7);
			background-color: var(--color-neutral-2);
		}

		&:disabled {
			color: var(--color-neutral-7);
			border-color: var(--color-neutral-2);
			background: transparent;
			pointer-events: none;
		}
	}

	&[fullwidth="true"] {
		width: 100%;
		flex: 1;
	}

	&[touppercase="false"] {
		text-transform: inherit;
	}

	&[variant="ghostThird"] {
		color: var(--color-primary-8);
		background-color: transparent;
		border-color: var(--color-neutral-2);
		text-transform: uppercase;
		font-weight: 600;

		svg {
			path {
				stroke: var(--color-generic-white);
			}
		}

		&:hover {
			border-color: var(--color-neutral-2-hover);
			background-color: var(--color-neutral-2-hover);
			color: var(--color-generic-white);
		}

		&:active {
			border-color: var(--color-neutral-2);
			background-color: var(--color-neutral-2);
		}

		&:disabled {
			border-color: var(--color-neutral-10);
			background-color: var(--color-neutral-10);
			pointer-events: none;
		}
	}
}
